// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contributions-mdx": () => import("./../../../src/pages/contributions.mdx" /* webpackChunkName: "component---src-pages-contributions-mdx" */),
  "component---src-pages-demo-index-mdx": () => import("./../../../src/pages/demo/index.mdx" /* webpackChunkName: "component---src-pages-demo-index-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */)
}

